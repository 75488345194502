
    import { Component, Vue, Watch, Mixins } from 'vue-property-decorator'
    import { Product } from '../models/Product'
    import { FilterMixin } from '@/mixins/filterMixin';
    import filter from '@/store/modules/filter'
    import azureProductSearch from '../services/azureSearchclient';

    const Pagination = () => import('../components/Pagination.vue')

    @Component({
        components: {
            Pagination
        }
    })
    export default class SubCategory extends  Mixins(FilterMixin) {
        currentPage = 1;
        paginationParam = {
            page: 1,
            limit: 20
        };

        async created() {
            if (this.$router.currentRoute.name == 'Search') {
                if(this.products.length == 0)
                {
                    await this.searchProducts();
                }
            } else {
                if (filter.selectedSubCategoryId) {
                    let categoryId = filter.selectedCategoryId
                    let subcategoryId = filter.selectedSubCategoryId
                    this.$axios
                        .get(`/api/products/categories/${categoryId}/subcategories/${subcategoryId}`, { params: this.paginationParam })
                        .then((res) => {
                            filter.saveProducts(res.data);
                        });
                } else {
                    let categorySlug = this.$route.params.categorySlug
                    let subCategorySlug = this.$route.params.subCategorySlug
                    this.$axios
                        .get(`/api/products/categories/${categorySlug}/subcategories/${subCategorySlug}`, { params: this.paginationParam })
                        .then((res) => {
                            filter.saveProducts(res.data);
                        });
                }
            }

        }

        async searchProducts() {
            let keyword = String(this.$router.currentRoute.query.keyword ?? "");

            if (keyword && keyword !== "")
            {
                const searchResults: any = await azureProductSearch.search(this.fuzzyQuerySearch(keyword), {
                    includeTotalCount: true,
                    queryType: "full",
                    searchMode: "all",
                    top: this.paginationParam.limit,
                    skip: this.paginationParam.limit * (this.paginationParam.page - 1)
                });

                const products: Product[] = [];

                for await (const result of searchResults.results) {
                    const product = {
                        id: result.document["Id"],
                        sku: result.document["SKU"],
                        description: result.document["Description"],
                        mainImage: result.document["MainImage"],
                        bulletPoints: result.document["BulletPoints"],
                        additionalImages: result.document["AdditionalImages"],
                        friendlyUrl: result.document["FriendlyUrl"],
                        upc: result.document["UPC"],
                        collectionDescription: result.document["CollectionDescription"],
                        assemblyRequirements: result.document["AssemblyRequirements"],
                        ageLevel: result.document["AgeLevel"],
                        warranty: result.document["Warranty"],
                        filterId: result.document["FilterId"],
                        filterName: result.document["FilterName"],
                        filterFriendlyUrl: result.document["FilterFriendlyUrl"]
                    } as Product;

                    products.push(product);
                }
                const results = {
                    data: products,
                    total: searchResults.count
                }

                filter.saveProducts(results);
            }
        }

        get products() {
            let product: Product = filter.paginationResult.products[1];
            return filter.paginationResult.products;
        }

        get totalPage() {
            return filter.paginationResult.total % this.paginationParam.limit == 0
                ? Math.floor(filter.paginationResult.total / this.paginationParam.limit)
                : Math.floor(filter.paginationResult.total / this.paginationParam.limit) + 1
        }

        getURL(item: Product) {
            let sub = filter.filters.find(x => x.friendlyUrl == item.filterFriendlyUrl);
            let cat = filter.filters.find(x => x.id == sub?.parentId);
            return { name: 'Product', params: { productSlug: item.friendlyUrl, categorySlug: cat?.friendlyUrl, subCategorySlug: sub?.friendlyUrl } };
        }

        goToPage(page:number)
        {
            this.currentPage = page;
            this.paginationParam.page = page;
            if (this.$router.currentRoute.name == 'Search') {
                this.searchProducts();
            }
            else {
                let categorySlug = this.$route.params.categorySlug
                let subCategorySlug = this.$route.params.subCategorySlug
                this.$axios.get(`/api/products/categories/${categorySlug}/subcategories/${subCategorySlug}`, { params: this.paginationParam })
                    .then((res) => {
                        filter.saveProducts(res.data);
                    });
            }
        }
    }
