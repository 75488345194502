
import { Component, Vue, Watch } from 'vue-property-decorator'
import filter from '@/store/modules/filter'
import { Filter } from '@/store/modules/filter/types'

const LeftMenu = () => import('../components/LeftMenu.vue')
const BreadCrumb = () => import('../components/BreadCrumbs.vue')

Component.registerHooks(['beforeRouteUpdate'])

@Component({
  components: {
    LeftMenu,
    BreadCrumb
  }
})
export default class ProductBrowsing extends Vue {
    async created() {
    await filter.fetchFilters();
    this.readRoute(this.$route);
  }

    async beforeRouteUpdate(to: any, from: any, next: any) {
    const categorySlug = to.params?.categorySlug;
    const subCategorySlug = to.params?.subCategorySlug;
    filter.actionSaveSelectedCategoryId(categorySlug);
    filter.actionSaveSelectedSubcategoryId(subCategorySlug);
    next();
  }

  readRoute(route: any) {
    const categorySlug = route.params?.categorySlug;
    const subCategorySlug = route.params?.subCategorySlug;

    filter.actionSaveSelectedCategoryId(categorySlug);
    filter.actionSaveSelectedSubcategoryId(subCategorySlug);
  }
}
