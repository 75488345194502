
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import product from '@/store/modules/product'

    @Component
    export default class ProductPage extends Vue {
        selectImage = 0
        get product() {
            return product.product
        }

        get images() {
            return [this.product.mainImage, ...this.product.additionalImages.split('|')]
        }

        async created() {
            await product.fetchProduct(encodeURIComponent(this.$route.params.productSlug))

            //if (filter.selectedSubCategoryId) {
            //    console.log('a');
            //}
            // if (filter.selectedProductId) {
            //   let id = filter.selectedProductId
            //   this.$axios.get<Product>(`/api/products/${id}`).then((res) => (this.product = res.data))
            // } else {
            //   let productName = encodeURIComponent(this.$route.params.productSlug)
            //   this.$axios
            //     .get<Product>(`/api/products/${productName}`)
            //     .then((res) => (this.product = res.data))
            // }
        }

        get productSlug() {
            return this.$route.params.productSlug;
        }

        @Watch('productSlug')
        async onCategoryIdChanged(val: number) {
            await product.fetchProduct(encodeURIComponent(this.$route.params.productSlug))
        }
    }
