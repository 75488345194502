
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import filter from '@/store/modules/filter'
import { FilterMixin } from '@/mixins/filterMixin';
import { Filter } from '@/store/modules/filter/types';

@Component
export default class Category extends Mixins(FilterMixin) {
  filters: Filter[] = []

  get subCategories() {
    return filter.selectedCategorySubcategories;
  }
}
