import { VuexModule, Module, MutationAction, getModule, Action } from 'vuex-module-decorators'
import store from '@/store'
import axios from 'axios'
//import { Product } from './types';
import { Product } from "../../../models/Product";

@Module({ namespaced: true, name: 'products', store, dynamic: true })
class ProductModule extends VuexModule {
  product: Product = {} as Product;
  @MutationAction
  async fetchProduct(productSlug: string) {
    const response = await axios.get(`/api/products/products/${productSlug}`);
    return { product: response.data }
  }
}

export default getModule(ProductModule);