
import { FilterMixin } from '@/mixins/filterMixin';
import filter from '@/store/modules/filter';
import { Component, Mixins, Vue } from 'vue-property-decorator'

@Component
export default class ProductCatalog extends Mixins(FilterMixin) {
  get categories() {
    return filter.categories;
  }
}
